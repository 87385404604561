import loginscreen from "@content/guides/images/loginscreen.png";
import forgottenpassword from "@content/guides/images/forgottenpassword.png";
import subscribe from "@content/guides/images/subscribescreen.png";

import { LockOpen } from "@mui/icons-material";

import { PageLayout, Section, SubSection, Note } from "@components";

import "@styles/Page.scss";

export default function GuideLogin() {
  return (
    <PageLayout
      title="Connexion et dépannage"
      titleIcon={<LockOpen />}
      titleClassName="guide"
    >
      <Section
        id="connexion-à-dotter-science"
        title="Connexion à Dotter.science"
      >
        <SubSection title="Accéder à la plateforme">
          <p>
            Il existe deux instances de Dotter.science présentes en ligne :
            <li>
              Instance de "<b>démonstration</b>" :{" "}
              <a href="https://demo.dotter.science" target="_blank">
                <b>https://demo.dotter.science</b>
              </a>
            </li>
            <li>
              Instance de "<b>officielle</b>" avec serveurs pour l'hébergement
              de données de santé :{" "}
              <a href="https://demo.dotter.science" target="_blank">
                <b>https://hds.dotter.science</b>
              </a>
            </li>
          </p>

          <Note type="important">
            Un compte différent est requis pour accéder à chaque instance. La
            connexion se fait via une addresse e-mail professionnelle et un mot
            de passe sécurisé.
          </Note>
          <img
            src={loginscreen}
            alt="Écran de connexion"
            className="centered"
          />
          <p>
            En arrivant sur l'instance, si <b>vous êtes déjà inscrit</b>, il
            vous suffit d'indiquer votre adresse e-mail (qui a servi pour
            l'inscription) et votre mot de passe puis cliquer sur{" "}
            <b>Connexion</b>.
          </p>
          <p>
            Si{" "}
            <b>
              vous n'êtes pas encore inscrit et que vous souhaitez créer une
              étude
            </b>
            , vous pouvez en faire la demande en cliquant sur <b>Inscription</b>
            . Un formulaire s'ouvre pour que vous puissiez en faire la demande,
            complétez-le puis cliquez sur <b>Envoyer</b>.
          </p>
          <p>
            Si{" "}
            <b>
              vous n'êtes pas encore inscrit et que vous souhaitez rejoindre une
              étude
            </b>
            , demandez à l'administrateur de votre étude de vous ajouter depuis
            les <b>Paramètres de l'étude</b> en indiquant votre adresse e-mail.
            Vous recevrez un e-mail vous indiquant que vous avez été invité à
            rejoindre l'étude accompagné d'un lien pour finaliser votre
            inscription. Cliquez dessus puis complétez le formulaire pour
            valider votre inscription.
          </p>
          <img
            src={subscribe}
            alt="Finaliser son inscription"
            className="centered"
          />
        </SubSection>
        <SubSection title="Première connexion">
          <p>
            Lors de la première connexion, il est recommandé de compléter les
            informations relatives à votre profil (voir guide sur la{" "}
            <a href="/fr/guides/profile">
              <b>gestion du profil</b>
            </a>
            ). Redéfinissez votre <b>mot de passe sécurisé</b> et indiquez{" "}
            <b>vos coordonnées</b> ainsi que <b>vos affiliations</b> pour que
            nos équipes puissent vous dépanner le plus rapidement possible en
            cas de problème.
          </p>
        </SubSection>
      </Section>
      <Section
        id="en-cas-d-oubli-de-mot-de-passe"
        title="En cas d'oubli de mot de passe"
      >
        <p>
          Si vous avez oublié votre mot de passe de connexion, complétez le
          champ "<em>Adresse e-mail</em>", un bouton "
          <b>J'ai oublié mon mot de passe</b>" apparaît alors en dessous du
          formulaire de connexion. Cliquez dessus, un lien vous sera envoyé par
          e-mail afin de réinialiser votre mot de passe (la réception du mail
          peut prendre quelques minutes)
        </p>
        <img
          src={forgottenpassword}
          alt="Mot de passe oublié"
          className="centered"
        />
        <h2 id="en-cas-d-oubli-d-adresse-e-mail">
          En cas d'oubli d'adresse e-mail
        </h2>
        <p>
          Si vous avez oublié votre adresse e-mail de connexion, contactez
          l'administrateur de votre étude afin qu'il vous la communique de
          nouveau.
        </p>
      </Section>
    </PageLayout>
  );
}
